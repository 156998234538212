.search {
  margin-bottom: 15px;
}
.main-container {
  margin: 0 6px;
}
/* tabs  */
.tabs {
  list-style: none;
  padding: 0;
  display: flex;
  margin: 0;
}
.tab-item {
  width: 100%;
  text-align: center;
  height: 35px;
}
.tab-item.active {
  color: black;
  border-bottom: 1px solid black;
}
.tab-item > a {
  text-decoration: none;
}
.hr-line-tab {
  margin: 0;
  margin-bottom: 15px;
}
