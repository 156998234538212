.main {
  margin-bottom: 10px;
}
.main-body {
  display: flex;
}
.left-container {
  display: flex;
  margin-right: auto;
  gap: 5px;
}
.content {
}

.img-container {
  display: flex;
  align-items: center;
}
.img-container > a > img {
  width: 44px;
  border-radius: 50%;
}
.name {
  margin-bottom: 1px;
}
.username {
  color: rgba(33, 37, 41, 0.75);
  margin-bottom: 0;
}
.link-text {
  color: black;
  text-decoration: none;
}
.link-text:hover {
  text-decoration: underline;
}
