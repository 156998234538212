.crop-container {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.cropper {
  position: relative;
  height: 50vh;
}

.viewer {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.output {
  position: relative;
  width: 300px;
  overflow: hidden;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.3);
}

.output img {
  position: absolute;
  top: 0;
  left: 0;
  transform-origin: top left;
}

.cropContainer {
  position: relative;
  width: 100%;
  height: 300px;
  background: #333;
}

#cropRange {
  background-color: black;
}
.croppedImage {
  width: 500px;
  border-radius: 50%;
}
