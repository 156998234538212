.post-card {
  margin-bottom: 10px;
}
.card-top {
  display: flex;
  padding: 7px 16px;
}
.profile-pic {
  width: 40px;
  border-radius: 50%;
  margin-right: 8px;
}
.card-top-right {
  display: flex;
  flex-direction: column;
}
.card-top-right > a {
  color: black;
  text-decoration: none;
}
.card-top-right > a:hover {
  text-decoration: underline;
}
.username {
  font-weight: 700;
  line-height: 1;
  margin-bottom: 6px;
}
.post-date {
  line-height: 1;
  font-size: 12px;
  color: rgba(33, 37, 41, 0.75);
}
.like-btn {
  margin-right: 20px;
}

.card-bottom {
  padding: 7px 15px;
}
.post-text {
  margin-bottom: 6px;
}
.comment-username {
  font-weight: 700;
  font-size: 14px;
}
.comment-text {
  font-size: 14px;
}
.view-more-comments {
  font-size: 14px;
  color: rgba(33, 37, 41, 0.75);
}
.view-more-comments:hover {
  text-decoration: underline;
  cursor: pointer;
}
