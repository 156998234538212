.card {
  max-width: 25rem;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  margin: auto;
  padding: 1.25rem;
}

.card h2 {
  font-size: 2rem;
  margin: 0;
  margin-bottom: 15px;
  font-weight: 500;
  text-align: center;
  font-family: 'Satisfy', cursive;
}

.card p {
  margin: 0;
  text-align: center;
}

.form-class {
  margin-top: 100px;
}

.form-sub {
  margin-top: 30px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f7f7f7;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 15px;
}

.form-control:focus {
  color: black;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
}

.button {
  display: block;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
  color: #fff;
  background-color: #007bff;
  border: 1px solid transparent;
  border-color: #007bff;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-top: 15px;
}

.button:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}
