.profile {
  margin-top: 130px;
  margin-bottom: 15px;
}

.profile-container > .profile-content {
  padding-top: 25px;
  text-align: center;
}
.profile-container > .profile-content > h5 {
  font-weight: 700;
}

.profile-img {
  border-radius: 50%;
  position: absolute;
  width: 120px;
  top: -57px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}
.birthday {
  display: flex;
  justify-content: center;
  align-items: center;
}
.birthday > p {
  margin: 0;
}
.birthday > img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.join-date {
  color: rgba(33, 37, 41, 0.75);
  margin: 0;
}

.edit-btn-container {
  height: 48px;
  display: flex;
}
.edit-btn {
  width: 110px;
  margin: 10px 10px 0 auto;
}

.delete-btn {
  width: 128px;
  margin: 10px 5px 0 10px;
}

.posts-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 5px;
}
.post-item {
  background-color: black;
  aspect-ratio: 1/1;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  cursor: pointer;
}
.post-item img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* profile stats  */
.profile-stats {
  display: flex;
  margin-top: 6px;
}
.stat-item {
  width: 100%;
}
.stat-no {
  font-weight: 600;
}
.stat-name {
  font-size: 14px;
}

/* under 500px */
@media only screen and (max-width: 500px) {
  .delete-btn,
  .edit-btn {
    width: 100px;
    font-size: 0.7rem;
    padding-top: 9px;
    font-weight: 600;
  }
}
