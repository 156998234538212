.post-modal {
  z-index: 100;
  /* max-width: 1100px; */
  width: auto;
  top: 12vh;
  left: 50%;
  width: 90%;
  /* height: 80%; */
  transform: translateX(-50%);
  position: fixed;
  box-sizing: border-box;
  border-radius: 5px;
  overflow: hidden;
  display: block;
}
.modal-mobile {
  display: none;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.75);
}

.post-container {
  display: flex;
  flex-wrap: nowrap;
  max-height: 80vh;
  /* width: 100%; */
  /* aspect-ratio: 16/9; */
}
.post-left-section {
  /* border-radius: 100px; */
  display: flex;
  align-items: center;
  background: black;
  border-radius: 5px 0 0 5px;
  width: 100%;
}
img.post-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.post-right-section {
  flex-basis: 0;
  flex-grow: 0;
  background: white;
  border-radius: 0 5px 5px 0;
  /* padding: 15px; */
}
.post-right-container {
  width: 400px;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.post-right-header-container {
  padding: 10px 15px;
}
.post-right-header {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.post-user-image {
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
}
.post-username {
  line-height: 1;
  font-weight: 700;
}
.close-btn {
  margin-left: auto;
  align-self: start;
}
.post-text {
  font-size: 14px;
  line-height: 1.3;
}
.hr-line {
  margin: 0;
  opacity: 0.15;
  color: black;
}
.comments-container {
  padding: 10px 15px;
  height: 100%;
  overflow-y: scroll;
  min-height: 250px;
}
.comment {
  margin-bottom: 6px;
}

.comment-username {
  font-weight: 700;
  font-size: 14px;
}
.comment-text {
  font-size: 14px;
  line-height: 1.3;
}
.comment-time {
  font-size: 13px;
  color: rgb(115, 115, 115);
}
.view-more-comments {
  font-size: 14px;
  color: rgba(33, 37, 41, 0.75);
}
.view-more-comments:hover {
  text-decoration: underline;
  cursor: pointer;
}
.post-details-container {
  padding: 10px 15px;
}
.like-btn {
  margin-right: 20px;
}
.post-date {
  color: rgb(115, 115, 115);
  font-size: 13px;
}
.add-comment-container {
  display: flex;
}
.comment-textarea {
  width: 100%;
  border: none;
  height: 45px;
  padding: 10px 15px;
  resize: none;
}
.comment-textarea:focus {
  outline: none;
}
.post-btn-container {
  display: flex;
}
.post-btn {
  border: none;
  width: 60px;
  font-weight: 700;
  border-radius: 0;
}
.delete-btn {
  margin-left: 30px;
  cursor: pointer;
}

.mobile-header-container,
.mobile-text-container {
  display: none;
}

.pin-icon {
  height: 12px;
  transform: rotate(45deg);
  margin-right: 4px;
}
.mobile-scroll-container {
  overflow-y: scroll;
}

/* under 800px */
@media only screen and (max-width: 800px) {
  .post-modal {
    top: 2vh;
    height: 97%;
    overflow: scroll;
    display: flex;
    align-items: center;
  }
  .modal-desktop {
    display: none;
  }
  .post-container {
    flex-direction: column;
    overflow: hidden;
    max-height: unset;
    border-radius: 5px;
    width: 100%;
  }
  .post-right-container {
    width: 100%;
  }
  .mobile-header-container.post-right-header-container {
    display: block;
    background-color: white;
    border-radius: 6px 6px 0 0;
    padding: 3px 15px;
    border-bottom: 1px solid rgb(128 128 128 / 42%);
  }
  .mobile-text-container {
    display: block;
    padding: 5px 15px;
    font-size: 0.8rem;
  }
  .mobile-scroll-container {
    max-height: 70vh;
  }
  .post-left-section {
    border-radius: 0;
  }
  .post-right-header-container {
    display: none;
  }
  .post-right-section {
    border-radius: 0;
  }
  .close-btn {
    align-self: center;
  }
  .post-image {
    max-height: 400px;
  }
  .comments-container {
    max-height: 300px;
    overflow-y: unset;
  }
}

/* under 500px */
@media only screen and (max-width: 500px) {
  .post-modal {
    width: 98%;
  }
}
