.App {
  text-align: center;
}
section {
  margin: auto;
  margin-top: 70px;

  margin-bottom: 15px;
  position: relative;
}
.section-1 {
  max-width: 30rem;
}
.section-2 {
  margin-left: 100px;
  margin-right: 100px;
}

/* under 500px */
@media only screen and (max-width: 600px) {
  section {
    margin-bottom: 75px;
  }
}
