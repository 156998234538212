.navbar-new {
  background-color: #343a40;
  display: flex;
  min-height: 50px;
  align-items: center;
  padding: 0 30px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 5;
}

.navbar-new > ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
  list-style: none;
}

.navbar-new > ul > li {
  display: flex;
  align-items: center;
}
.navbar-new > ul > li > a,
.create-link {
  text-decoration: none;
  font-size: 18px;
  cursor: pointer;
  margin: 0;
}
.navbar-new > ul > .nav-link > a,
.create-link {
  color: rgba(248, 249, 250, 0.65);
}
.navbar-new > ul > .nav-link:hover > a,
.create-link {
  color: rgba(248, 249, 250, 0.8);
}
.navbar-new > ul > .nav-link > a.nav-link-active {
  color: rgba(248, 249, 250, 1);
}

.nav-right {
  margin-left: auto !important;
}

.profile-img {
  margin-left: auto;
}
.profile-img > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.mobile-settings > img {
  width: 25px;
}

.navbar-main {
  background-color: #343a40;
  padding-left: 20px;
  padding-right: 20px;
}

.nav-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.nav-logo > a {
  font-family: 'Pacifico', cursive;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: white !important;
}
.nav-logo {
  margin-right: 10px;
}
.nav-logo > a > img {
  margin-right: 5px;
  width: 34px;
}

.nav-search {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #adb5bd;
  background-color: white;
  background-clip: padding-box;
  border: 1px solid #495057;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.nav-search:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-form-control-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.nav-dropdown {
  position: absolute;
  right: 27px;
  top: 52px;
  min-width: 150px;
  z-index: 5;
}
.nav-dropdown-item {
  cursor: pointer;
}
.nav-dropdown-item > a {
  text-decoration: none;
  color: black;
  display: block;
}

/* Modal  */
.modal-container {
  max-height: 500px;
  overflow-y: scroll;
}
#postTextArea {
  resize: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
}

.upload-image-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  cursor: pointer;
}
.upload-image-btn:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.upload-image-btn p {
  margin: 0;
}
.upload-image-btn label {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

#upload-input {
  display: none;
}

.upload-label {
  cursor: pointer;
  width: 100%;
  margin-bottom: 1rem;
}
.upload-card {
  display: flex;
  height: 50px;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
}
.upload-card:hover {
  background: rgba(0, 0, 0, 0.1);
}
.upload-card > p {
  margin: 0;
  font-weight: 600;
}

.preview-image-container {
  overflow: hidden;
  position: relative;
}
.post-btn-container {
  margin-top: 15px;
}
.img-close-btn {
  position: absolute;
  background-color: white;
  width: 39px;
  height: 39px;
  border-radius: 50%;
  top: 10px;
  right: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.img-close-btn:hover {
  background-color: #dee2e6;
}

.spinner-container {
  text-align: center;
  margin-top: 5px;
}
/* .preview-image-container > img {
  max-height: 200px;
} */

/* under 500px */
@media only screen and (max-width: 600px) {
  .nav-dropdown-item {
    padding: 15px;
  }
}
